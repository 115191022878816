import $ from "jquery";
import toastr from "toastr";
import bootstrapBundle from "bootstrap/dist/js/bootstrap.bundle";
import Icon from "./../../media/images/Logo.png";
import { initNotification } from "../../modules/Notifications/Notifications";
var Config, SignedInUser, AccessControl;
$.ajax({
  url: "api/uiModules/Common.fetch.php?action=initialize",
  method: "POST",
  data: {},
  async: false,
  success: function (result) {
    result = JSON.parse(result);
    Config = result.config;
    SignedInUser = result.signedinuser;
    AccessControl = JSON.parse(result.accesscontrol);
  },
});
export { Config, SignedInUser, AccessControl };
toastr.options.positionClass = "toast-top-center";

export function getUserImage(usr) {
  if (usr.profileUrl != "") {
    return Config["site_url"] + "/content/profileImages/" + usr.profileUrl;
  } else if (usr.gData.thumbnailUri != "") {
    return usr.gData.thumbnailUri;
  } else {
    return Config["site_url"] + '/content/profileImages/Default.png';
  }
}

export function getRandomColor() {
  return "hsl(" + Math.random() * 360 + ", 100%, 30%, 0.7)";
}

export function hexToHsl(hex) {
  var r = parseInt(hex.substr(1, 2), 16); // Grab the hex representation of red (chars 1-2) and convert to decimal (base 10).
  var g = parseInt(hex.substr(3, 2), 16);
  var b = parseInt(hex.substr(5, 2), 16);

  let hsl = rgbToHsl(r, g, b);

  let s = hsl[1] * 100;
  s = Math.round(s);
  let l = hsl[2] * 100;
  l = Math.round(l);
  let h = Math.round(360 * hsl[0]);

  return "hsl(" + h + ", " + s + "%, " + l + "% , 0.7)";
  // return "hsl(" + h + ", " + "100%, " + "30%)";
}

function rgbToHsl(r, g, b) {
  (r /= 255), (g /= 255), (b /= 255);
  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    l = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [h, s, l];
}

export function pageAccessRefresh(pageName, type) {
  //Check if user has read access
  if (!AccessControl[SignedInUser.type][pageName]["read"]) {
    $("div.content-wrapper").remove();
    toastr.error("Contact the administrator, Back to Home", "Invalid Access", {
      positionClass: "toast-bottom-right",
    });
    setTimeout(function () {
      window.location.replace(Config.app_path + "?module=Home");
    }, 3000);
    return;
  }

  //Hiding anything for read-only users
  if (
    AccessControl[SignedInUser.type][pageName]["read"] &&
    !AccessControl[SignedInUser.type][pageName]["write"] &&
    !AccessControl[SignedInUser.type][pageName]["edit"]
  ) {
    $(".hide-read").addClass("d-none");
  }

  //Disable all elements with access-read
  $(".access-read").attr("disabled", true);

  //Enable for type of access
  // let type = ["write", "edit"];
  type.forEach((element) => {
    if (AccessControl[SignedInUser.type][pageName][element]) {
      //Remove disabled class for elements
      // $(".access-" + element).removeClass("disabled");
      $(".access-" + element).attr("disabled", false);
    }
  });
}
String.prototype.toCamelCase = function () {
  return this.toLowerCase()
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function ($1) {
      return $1.toUpperCase();
    });
};

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
var $_GET = {};
document.location.search.replace(/\??(?:([^=]+)=([^&]*)&?)/g, function () {
  function decode(s) {
    return decodeURIComponent(s.split("+").join(" "));
  }
  $_GET[decode(arguments[1])] = decode(arguments[2]);
});
window.onload = function () {
  var link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.rel = "icon";
  link.href = Icon;
  document.getElementsByTagName("head")[0].appendChild(link);

  document.getElementById("logoFrontAndCenter").src = Icon;
  document.getElementById("logoOverlay").src = Icon;
  //get cookie here and check
  let isAdminView = getCookie("admin_view");
  if (isAdminView == 1) {
    $("#admin_view").attr("checked", "checked");
  }
  $("#admin_view").on("change", function () {
    let body;
    if ($(this).is(":checked")) {
      document.cookie = "admin_view=1;path=/";
      body = "Reloading to Admin View!";
    } else {
      document.cookie = "admin_view=0;path=/";
      body = "Reloading to Plan Manager View!";
    }
    window.setTimeout("location.reload()", 1000); //Reloads after three
    toastr.info(body, "Reloading");
  });
  if ($_GET["module"]) {
    $("#" + $_GET["module"].toLowerCase() + "_link").addClass("active");
  }

  $("#plansearchdd").on("keyup", function () {
    var value = $(this).val().toLowerCase();
    $("#planselectdd a").filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  });

  [...document.querySelectorAll('[data-bs-toggle="tooltip"]')].forEach(
    (el) => new bootstrapBundle.Tooltip(el)
  );

  //Notif init
  function clearIntervalNotif() {
    clearInterval(notifCheckInterval);
  }
  let notifCheckInterval = setInterval(() => {
    if (this.document.getElementById("notificationsection")) {
      clearIntervalNotif();
      initNotification();
    }
  }, 100);
};
export { $_GET };
