import $ from "jquery";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { reloadNotifications } from "./Notifications";
export const firebaseConfig = {
  apiKey: "AIzaSyDxwzBEwDCdzMHiL4kTIZlUo18qgkQzSMI",
  authDomain: "reno-decbb.firebaseapp.com",
  projectId: "reno-decbb",
  storageBucket: "reno-decbb.appspot.com",
  messagingSenderId: "433311512311",
  appId: "1:433311512311:web:b81b5ea7ea0b60eabc85f2",
};

// Initialize Firebase
let app = initializeApp(firebaseConfig);
let messaging = getMessaging(app);

export function getServiceWorker() {
  // Ensure that the browser supports the service worker API
  if (navigator.serviceWorker) {
    navigator.serviceWorker
      // The register function takes as argument
      // the file path to the worker's file
      .register("./fcm-sw.js", { type: "module" })
      // Gives us registration object;
      .then((reg) => setupFireBase(reg))
      .catch((swErr) =>
        console.log(`Service Worker Installation Error: ${swErr}}`)
      );
  }
}

function setupFireBase(serviceWorker) {
  // Add the public key generated from the console here.
  getToken(messaging, {
    vapidKey:
      "BCBwj5F5x7UPqrzMtgLots-8InCOp2ZtVdKHu39fJgyrJQMd6cE5YIqdbdksa8cqps_JBJiOlKVfIuzvd6f1wWs",
    serviceWorkerRegistration: serviceWorker,
  })
    .then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        console.log("Received token " + currentToken);
        updateFcmDetails(currentToken);
        // ...
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // ...
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // ...
    });
}

function updateFcmDetails(fcmid) {
  $.ajax({
    url: "api/uiModules/Users.upsert.php?action=updateFcmDetails",
    method: "POST",
    data: {
      data: JSON.stringify({
        fcmtoken: fcmid,
        useragent: navigator.userAgent,
      }),
    },
    success: function (result) {
      result = JSON.parse(result);
      if (result.status != "success") {
        console.log("error updating the token, " + JSON.stringify(result));
      }
    },
    error: function () {
      console.log("Error occured in request");
    },
  });
}

onMessage(messaging, () => {
  new Audio("https://reno.veniso.com/cp/content/Audio/short_bongo.mp3").play();
  reloadNotifications();
});
