import { Config } from "../../app/templates/commonscript";

export const filterListTemplate =
  '<li class="dropdown-item"><label class="row justify-content-between mb-0" for="filter_{VALUE}"><div class="col-10 text-truncate"><label class="font-weight-normal mb-0" for="filter_{VALUE}">{DISPLAY_NAME}</label></div><div class="col text-right"><input id="filter_{VALUE}" name="filter_{NAME}" type="checkbox" value="{VALUE}" {CHECKED}/></div></label></li>';

export function getPlanEditButton(planid) {
  let classNames = [
    "btn",
    "px-1",
    "py-1",
    "btn-sm",
    "access-read",
    "access-write",
    "access-edit",
  ];
  let attributes = {
    "data-bs-toggle": "modal",
    "data-bs-target": "#editplanmodal",
    "data-bs-planid": planid,
  };
  let iconAWrapper = getATag(classNames, attributes);
  classNames = ["fa-regular", "fa-pen-to-square", "align-middle"];
  attributes = {};
  let icon = getIconElement(classNames, attributes);
  iconAWrapper.innerHTML = icon.outerHTML;
  return iconAWrapper;
}

export function getTasksOfPlanButton(planid) {
  let classNames = ["btn", "btn-sm"];
  let attributes = {
    href: Config.app_path + "?module=Tasks&planid=" + planid,
  };
  let iconAWrapper = getATag(classNames, attributes);
  classNames = ["fa-solid", "fa-check-double", "align-middle"];
  attributes = {};
  let icon = getIconElement(classNames, attributes);
  iconAWrapper.innerHTML = icon.outerHTML;
  return iconAWrapper;
}

export function getMyFlaggedButton(taskid, isFlagged) {
  let classNames = [
    "btn",
    "px-1",
    "py-1",
    "btn-sm",
    "access-read",
    "access-write",
    "access-edit",
  ];
  let attributes = {
    "data-myflagged": "",
  };
  let iconAWrapper = getATag(classNames, attributes);
  attributes = {
    "data-bs-toggle": "tooltip",
    "data-myflagged-taskid": taskid,
  };
  if (isFlagged) {
    classNames = ["fa-solid", "fa-flag", "align-middle"];
    attributes["style"] = "color:dodgerblue";
    attributes["data-myflagged-selected"] = true;
    attributes["title"] = "Remove from Flagged";
  } else {
    classNames = ["fa-regular", "fa-flag", "align-middle"];
    attributes["data-myflagged-selected"] = false;
    attributes["title"] = "Add to Flagged";
  }
  let icon = getIconElement(classNames, attributes);
  iconAWrapper.innerHTML = icon.outerHTML;
  return iconAWrapper;
}

export function getMyDayButton(taskid, isMyDay) {
  let classNames = [
    "btn",
    "px-1",
    "py-1",
    "btn-sm",
    "access-read",
    "access-write",
    "access-edit",
  ];
  let attributes = {
    "data-myday": "",
  };
  let iconAWrapper = getATag(classNames, attributes);
  attributes = {
    "data-bs-toggle": "tooltip",
    "data-myday-taskid": taskid,
  };
  if (isMyDay) {
    classNames = ["fa-solid", "fa-calendar", "align-middle"];
    attributes["style"] = "color:darkorange";
    attributes["data-myday-selected"] = true;
    attributes["title"] = "Remove from MyDay";
  } else {
    classNames = ["fa-regular", "fa-calendar", "align-middle"];
    attributes["data-myday-selected"] = false;
    attributes["title"] = "Add to MyDay";
  }
  let icon = getIconElement(classNames, attributes);
  iconAWrapper.innerHTML = icon.outerHTML;
  return iconAWrapper;
}

export function getHoursButton(taskid, name) {
  let classNames = [
    "btn",
    "px-1",
    "py-1",
    "btn-sm",
    "access-read",
    "access-write",
    "access-edit",
  ];
  let attributes = {
    "data-bs-toggle": "modal",
    "data-bs-target": "#loghours",
    "data-bs-taskid": taskid,
    "data-reno-taskname": name,
  };
  let iconAWrapper = getATag(classNames, attributes);
  classNames = ["fa-regular", "fa-clock", "align-middle"];
  attributes = {
    "data-bs-toggle": "tooltip",
    title: "View Hours",
  };
  let icon = getIconElement(classNames, attributes);
  iconAWrapper.innerHTML = icon.outerHTML;
  return iconAWrapper;
}

export function getEditTaskButton(taskid, name, isSubTask = false) {
  let classNames = [
    "btn",
    "px-1",
    "py-1",
    "btn-sm",
    "access-read",
    "access-write",
    "access-edit",
  ];
  let attributes = {
    "data-bs-toggle": "modal",
    "data-bs-target": "#edittaskmodal",
    "data-bs-taskid": taskid,
    "data-reno-taskname": name,
    "data-reno-intent": "edittask",
  };
  if (isSubTask) {
    attributes["data-reno-intent"] = "editsubtask";
  }
  let iconAWrapper = getATag(classNames, attributes);
  classNames = ["fa-regular", "fa-pen-to-square", "align-middle"];
  attributes = {
    "data-bs-toggle": "tooltip",
    title: "Edit",
  };
  let icon = getIconElement(classNames, attributes);
  iconAWrapper.innerHTML = icon.outerHTML;
  return iconAWrapper;
}

export function getDateDisplayFromTimestamp(timestamp) {
  return new Date(timestamp * 1000).toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "2-digit",
  });
}

export function getDateAndTimestampDisplayFromTimestamp(timestamp) {
  return new Date(timestamp * 1000).toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
}

export function timeDifference(current, previous) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds ago";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours ago";
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + " days ago";
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + " months ago";
  } else {
    return Math.round(elapsed / msPerYear) + " years ago";
  }
}

export function getSubTaskCreateButton(taskid, name) {
  let classNames = [
    "btn",
    "px-1",
    "py-1",
    "btn-sm",
    "access-read",
    "access-write",
    "access-edit",
  ];
  let attributes = {
    "data-bs-toggle": "modal",
    "data-bs-target": "#edittaskmodal",
    "data-bs-taskid": taskid,
    "data-reno-taskname": name,
    "data-reno-intent": "createsubtask",
  };
  let iconAWrapper = getATag(classNames, attributes);
  classNames = [
    "fa-solid",
    "fa-arrow-right-from-bracket",
    "fa-rotate-90",
    "align-middle",
  ];
  attributes = {
    "data-bs-toggle": "tooltip",
    title: "Create Subtask",
  };
  let icon = getIconElement(classNames, attributes);
  iconAWrapper.innerHTML = icon.outerHTML;
  return iconAWrapper;
}

export function getPlanLink(planID) {
  let classNames = ["link-dark"];
  let attributes = {
    href: Config.app_path + "?module=Tasks&planid=" + planID,
  };
  let iconAWrapper = getATag(classNames, attributes);
  classNames = ["fa-solid", "fa-link", "fa-xs"];
  attributes = {};
  let icon = getIconElement(classNames, attributes);
  iconAWrapper.innerHTML = icon.outerHTML;
  return iconAWrapper;
}

export function formatFilterListEntry(displayName, name, value, checked) {
  return filterListTemplate
    .replace("{DISPLAY_NAME}", displayName)
    .replaceAll("{NAME}", name)
    .replaceAll("{VALUE}", value)
    .replaceAll("{CHECKED}", checked);
}

export function getATag(classNames, attributes) {
  let tmp = document.createElement("a");
  tmp.className = classNames.join(" ");
  for (let key in attributes) {
    tmp.setAttribute(key, attributes[key]);
  }
  return tmp;
}

export function getIconElement(classNames, attributes) {
  let tmp = document.createElement("i");
  tmp.className = classNames.join(" ");
  for (let key in attributes) {
    tmp.setAttribute(key, attributes[key]);
  }
  return tmp;
}

export function getDivTag(classNames, attributes) {
  let tmp = document.createElement("div");
  tmp.className = classNames.join(" ");
  for (let key in attributes) {
    tmp.setAttribute(key, attributes[key]);
  }
  return tmp;
}
