import $ from "jquery";
import toastr from "toastr";
import { getServiceWorker } from "./FcmReg";
import {
  getATag,
  getDivTag,
  getIconElement,
  timeDifference,
} from "../Utils/UiUtils";
import bootstrapBundle from "bootstrap/dist/js/bootstrap.bundle";
let lastScrollTop = 0;
let isLoading = false;
let page = 0;
let notifpermissiongiven = false;

export function initNotification() {
  //Check if notifications are already enabled
  if (Notification.permission != "granted") {
    requestNotificationPermissionWithToast().then(
      () => {
        getServiceWorker();
      },
      () => {
        toastr.error("I'll let someone know!", "Hmmm!");
      }
    );
  } else {
    getServiceWorker();
  }
  loadNotifications();
  registerScrollEvent();
  registerButtons();
  window.addEventListener(
    "focus",
    function () {
      reloadNotifications();
    },
    false
  );
}

function registerScrollEvent() {
  let element = document.getElementById("notifparentdiv");
  element.addEventListener("scroll", function () {
    if (element.scrollTop < lastScrollTop) {
      // upscroll
      return;
    }
    lastScrollTop = element.scrollTop <= 0 ? 0 : element.scrollTop;
    if (element.scrollTop + element.offsetHeight < element.scrollHeight) {
      return;
    }
    if (isLoading) {
      return;
    }
    isLoading = true;
    loadNotifications();
  });
}

function registerButtons() {
  document
    .getElementById("markallreadnotif")
    .addEventListener("click", function () {
      $.ajax({
        url: "api/uiModules/Notifications.upsert.php",
        method: "POST",
        data: {
          data: JSON.stringify({
            action: "setAllRead",
            read: true,
          }),
        },
        success: function (result) {
          result = JSON.parse(result);
          if (result.status == "success") {
            reloadNotifications();
          }
        },
      });
    });
}

function loadNotifications() {
  //Add loading symbol
  $("#notificationloader").removeClass("d-none");
  fetchNotifications().then((notifications) => {
    appendNotificationsToUi(notifications);
    //Remove loading symbol
    $("#notificationloader").addClass("d-none");
    //Disable loading for sometime due to reaching end of scroll automatically
    setTimeout(() => {
      isLoading = false;
    }, 2000);
    checkIfUnreadAndEnableUnreadDot();
  });
}

function checkIfUnreadAndEnableUnreadDot() {
  let loadedNotifications = document
    .getElementById("notificationsection")
    .getElementsByTagName("i");
  for (let iTag of loadedNotifications) {
    if (iTag.getAttribute("data-reno-read") === "false") {
      document.getElementById("notifreaddot").classList.remove("d-none");
      document.getElementById("notifbell").classList.add("fa-shake");
      return;
    }
    document.getElementById("notifbell").classList.remove("fa-shake");
    document.getElementById("notifreaddot").classList.add("d-none");
  }
}

function fetchNotifications() {
  return apiRequestForNotifications().then((notificationsList) => {
    return new Promise((resolve) => {
      let notifs = [];
      notificationsList.forEach((notif) => {
        let notifTime = timeDifference(Date.now(), notif.timestamp * 1000);
        let notifText = processNotificationText(notif.text);
        notifs.push({
          id: notif["docID"]["$oid"],
          text: notifText,
          time: notifTime,
          read: notif.read,
        });
      });
      resolve(notifs);
    });
  });
}

function appendNotificationsToUi(notifications) {
  notifications.forEach((notif) => {
    document
      .getElementById("notificationsection")
      .appendChild(createNotificationDiv(notif));
  });
  [...document.querySelectorAll('[data-bs-toggle="tooltip"]')].forEach(
    (el) => new bootstrapBundle.Tooltip(el)
  );
}

function apiRequestForNotifications() {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: "api/uiModules/Notifications.fetch.php",
      method: "POST",
      data: {
        action: "getnotifications",
        page: page++,
        maxresults: 20,
      },
      success: function (result) {
        result = JSON.parse(result);
        if (result.length == 0) {
          //Since no data was sent, decrementing the pagination value
          page--;
        }
        resolve(result);
      },
      error: function () {
        reject();
      },
    });
  });
}

function requestNotificationPermissionWithToast() {
  // Create the button elements with the specified classes and attributes
  const takeActionButton = document.createElement("button");
  takeActionButton.classList.add("btn", "btn-outline-dark", "btn-sm", "m-2");
  takeActionButton.setAttribute("type", "button");
  takeActionButton.textContent = "Yes";
  takeActionButton.id = "notifpermissionaffirm";

  // Create the div element with the specified classes
  const mt2pt2BorderTopDiv = document.createElement("div");
  // mt2pt2BorderTopDiv.classList.add("mt-2", "pt-2");

  // Append the button elements to the div element
  mt2pt2BorderTopDiv.appendChild(takeActionButton);

  return new Promise(function (resolved, rejected) {
    var notifPermissionToastr = toastr.info(
      mt2pt2BorderTopDiv.outerHTML,
      "Enable Notifications!",
      {
        timeOut: "0",
        closeButton: true,
        extendedTimeOut: "0",
        onclick: function () {
          if (!notifpermissiongiven) {
            rejected();
          }
        },
        onCloseClick: function () {
          if (!notifpermissiongiven) {
            rejected();
          }
        },
      }
    );

    notifPermissionToastr.on(
      "click",
      "#notifpermissionaffirm",
      // eslint-disable-next-line
      function (event) {
        notifpermissiongiven = true;
        getNotificationPermissionFromBrowser().then(
          (permission) => {
            if (permission === "granted") {
              resolved();
              toastr.clear();
            }
            rejected();
          },
          () => {
            rejected();
          }
        );
      }
    );
  });
}

function getNotificationPermissionFromBrowser() {
  return Notification.requestPermission();
}

function processNotificationText(text) {
  const linkRefRE = new RegExp(/\[(.*?)\]{(.*?)}/g);
  let linkInfo,
    finalNotifText = text;
  while ((linkInfo = linkRefRE.exec(text)) != null) {
    let hRef = getLinkFromShortCode(linkInfo[2]);
    let aTag = getATag([], { href: hRef });
    aTag.innerHTML = linkInfo[1];
    finalNotifText = finalNotifText.replace(linkInfo[0], aTag.outerHTML);
  }
  return finalNotifText;
}

function getLinkFromShortCode(shortcode) {
  return "api/redirectWithShortcode.php?sc=" + shortcode;
}

function createNotificationDiv(notif) {
  let notifText = notif.text;
  let notifTime = notif.time;
  let notifRead = notif.read;
  let id = notif.id;
  let notifMainDiv = getDivTag(
    [
      "d-flex",
      "dropdown-item-text",
      "justify-content-between",
      "mb-1",
      "shadow-sm",
      "rounded",
    ],
    {}
  );
  let infoDivContainer = getDivTag(["d-flex", "flex-column"], {});
  let notifTextDiv = getDivTag([], {});
  notifTextDiv.innerHTML = notifText;
  let notifTimeDiv = getDivTag([], {
    style: "font-size: smaller;",
  });
  notifTimeDiv.innerHTML = notifTime;

  infoDivContainer.append(notifTextDiv, notifTimeDiv);

  let readDivContainer = getDivTag(
    ["align-self-center", "justify-content-end"],
    {}
  );

  let iconAWrapper = getATag(["btn", "px-1", "py-1", "btn-sm"], {});
  iconAWrapper.addEventListener("click", handleClickOnNotificationRead);
  let readIcon = getIconElement(["fa-regular", "fa-circle"], {
    style: "color: #4f74b5;",
    "data-bs-toggle": "tooltip",
    title: "Read",
    "data-reno-read": true,
    "data-reno-notifid": id,
  });
  if (!notifRead) {
    readIcon = getIconElement(["fa-solid", "fa-circle"], {
      style: "color: #4f74b5;",
      "data-bs-toggle": "tooltip",
      title: "Unread",
      "data-reno-read": false,
      "data-reno-notifid": id,
    });
  }
  iconAWrapper.innerHTML = readIcon.outerHTML;
  readDivContainer.append(iconAWrapper);

  notifMainDiv.append(infoDivContainer);
  notifMainDiv.append(readDivContainer);
  return notifMainDiv;
}

function handleClickOnNotificationRead() {
  let iTag = this.getElementsByTagName("i")[0];
  let currentreadstate = iTag.getAttribute("data-reno-read") === "true";
  let notifid = iTag.getAttribute("data-reno-notifid");
  let readstate = currentreadstate ? false : true;
  setNotificationReadState(notifid, readstate).then(() => {
    let iTag = this.getElementsByTagName("i")[0];
    if ($(iTag).attr("data-reno-read") == "false") {
      $(iTag).attr("class", "fa-regular fa-circle");
      $(iTag).attr("data-reno-read", true);
      $(iTag).attr("title", "Read");
    } else {
      $(iTag).attr("class", "fa-solid fa-circle");
      $(iTag).attr("data-reno-read", false);
      $(iTag).attr("title", "Unread");
    }
    checkIfUnreadAndEnableUnreadDot();
  });
}
function setNotificationReadState(notifid, readstate) {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: "api/uiModules/Notifications.upsert.php",
      method: "POST",
      data: {
        data: JSON.stringify({
          action: "setread",
          notifid: notifid,
          read: readstate,
        }),
      },
      success: function (result) {
        result = JSON.parse(result);
        if (result.status == "success") {
          resolve(result);
        }
        reject();
      },
      error: function () {
        reject();
      },
    });
  });
}
export function reloadNotifications() {
  document.getElementById("notificationsection").innerHTML = "";
  page = 0;
  loadNotifications();
}
