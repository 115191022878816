// //JS dependencies
import "jquery";
import "@popperjs/core/dist/umd/popper";
import "bootstrap/dist/js/bootstrap.bundle";
import "select2";
import "@eonasdan/tempus-dominus/dist/js/tempus-dominus";
import "datatables.net-dt";
import "chart.js/auto";
// import "./plugins/admin-lte/dist/js/adminlte";
import "admin-lte/dist/js/adminlte";
import "toastr";
// import "./plugins/summernote/summernote-lite";
import "summernote/dist/summernote-lite";
import "firebase/app";
import "firebase/messaging";

//Stylesheet dependencies
import "./media/fonts/exo/exofont.css";
import "./media/fonts/SourceSansPro/ssp.css";
import "admin-lte/dist/css/adminlte.css";
import "bootstrap/dist/css/bootstrap.css";
import "select2/dist/css/select2.css";
import "select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@eonasdan/tempus-dominus/dist/css/tempus-dominus.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "toastr/build/toastr.css";
import "summernote/dist/summernote-lite.css";
